<template>
  <div>
    <ChangePassword />
  </div>
</template>
<script>
import ChangePassword from '@/components/system/Auth/ForgotPassword/changePassword.vue'
export default {
  components: {
    ChangePassword
  },
}
</script>
