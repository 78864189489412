<template>
  <div :class="$style.auth">
    <div class="pt-5 pb-5 d-flex align-items-end mt-auto">
      <img src="resources/images/logo_abda.png" alt="abdalogo" />
    </div>
    <div class="pl-5 pr-5 pt-5 pb-5 bg-white text-center" :class="$style.container">
      <div class="text-dark font-size-30 mb-4">Redefinir Senha</div>
      <b-form class="mb-4">
        <b-form-group
          label="Senha:"
          label-for="password"
          :invalid-feedback="invalidPasswordFeedback"
          :state="passwordFieldState"
        >
          <b-form-input
            type="password"
            v-model="password"
            class="wizard-control"
            id="password"
            placeholder="Sua nova senha"
            name="password"
            v-validate="'required'"
            :state="passwordFieldState"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Confirmar senha:"
          label-for="confirm-password"
          :invalid-feedback="invalidConfirmPasswordFeedback"
          :state="confirmPasswordFieldState"
        >
          <b-form-input
            type="password"
            v-model="confirmPassword"
            class="wizard-control"
            id="confirm-password"
            placeholder="Confirme sua nova senha"
            name="confirm-password"
            v-validate="'required'"
            :state="confirmPasswordFieldState"
          ></b-form-input>
        </b-form-group>
        <button
          type="button"
          class="text-center btn btn-success w-100 font-weight-bold font-size-18 mt-3"
          :disabled="disableSubmitButton"
          @click="submitNewPassword"
        >Redefinir minha senha</button>
      </b-form>
    </div>
    <div class="text-center font-size-18 pt-4 mb-auto">
      <router-link :to="{ name: 'SignIn' }" class="font-weight-bold text-blue">
        <i class="fa fa-arrow-left align"></i> Fazer Login
      </router-link>
    </div>
    <div class="mt-auto pb-5 pt-5">
      <div class="text-gray-4 text-center">© {{ year }} Zeon Solutions. Todos os direitos reservados.</div>
    </div>
  </div>
</template>

<script>
import { isString } from 'lodash'
import AccountRepository from '@/shared/http/repositories/access/account'
import { IsSuccessCode } from '@/shared/utils/API'
import { toast, loading } from '@/shared/utils/UIHelper'
import { JWTService } from '@/shared/services/jwt'

export default {
  name: 'ChangePassword',
  data: () => ({
    password: '',
    confirmPassword: '',
    year: new Date().getFullYear()
  }),
  computed: {
    disableSubmitButton() {
      return (this.password !== this.confirmPassword) || this.password.length <= 5
    },

    invalidPasswordFeedback() {
      if (!isString(this.password) || this.password.length < 6) return 'A senha deve ter pelo menos 6 caracteres'
      return ''
    },

    passwordFieldState() {
      if (!isString(this.password)) return undefined
      return this.password.length >= 6
    },

    confirmPasswordFieldState() {
      if (!isString(this.confirmPassword)) return undefined
      return this.confirmPassword === this.password
    },

    invalidConfirmPasswordFeedback() {
      if (!isString(this.confirmPassword)) return undefined

      if (this.confirmPassword !== this.password) return 'As senhas não conferem'

      return ''
    }
  },
  methods: {
    submitNewPassword() {
      loading.push()
      AccountRepository.ResetPassword(this.$route.params.hash, this.password)
        .then((res) => {
          if (!IsSuccessCode(res)) return Promise.reject()

          loading.pop()
          toast.success('Senha redefinida com sucesso.')
          JWTService.SaveToken(res.data.data.token)
          Promise.resolve()

          return this.$router.replace({ name: 'Dashboard' })
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao redefinir senha.')
        })
    }
  }
}
</script>
<style lang="scss" module>
@import "@/components/system/Auth/style.module.scss";
</style>
